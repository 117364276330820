<template>
    <div class="page">
        <Toast ref="createToast"></Toast>
        <Header>New Campaign</Header>
        <NudgeForm @nudgeCreated="nudgeCreated" v-bind:editType="parent"></NudgeForm>
    </div>
</template>

<script>
import Header from '../../components/Header'
import Toast from '../../components/Toast'
import NudgeForm from '../../components/NudgeForm'
import { checkAuth } from '../../api/session.js'

export default {
    name: 'CreateView',
    data() {
        return {
            parent: 'create'
        }
    },
    components: {
        Header,
        Toast,
        NudgeForm
    },
    methods: {
        async checkIfLoggedIn() {
            try {
                await checkAuth()
            } catch (err) {
                this.$router.push('/login')
            }
        },
        nudgeCreated(message) {
            this.refs.createToast.setAlertMessage(message)
            this.refs.createToast.toggle()
        }
    },
    mounted() {
        this.checkIfLoggedIn()
    }
}
</script>

<style lang="scss"></style>
