import HomeView from '../../views/Home'
import CreateView from '../../views/Create'
import DuplicateView from '../../views/Duplicate'
import EditView from '../../views/Edit'
import LoginView from '../../views/Login'
import AccountEditView from '../../views/AccountEdit'

const state = () => [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/create',
        name: 'create',
        component: CreateView
    },
    {
        path: '/duplicate/:nudgeGroupId',
        name: 'duplicate',
        component: DuplicateView,
        props: true
    },
    {
        path: '/edit/:nudgeGroupId',
        name: 'edit',
        component: EditView,
        props: true
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/account_edit/:accountId',
        name: 'account_edit',
        component: AccountEditView,
        props: true
    },
    {
        path: '/account_create',
        name: 'account_create',
        component: AccountEditView
    }
]

// Getters
const getters = {}

// Actions
const actions = {}

// Mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
