<template>
    <div class="form">
        <div class="nudge-form form-section">
            <h3>Campaign Settings</h3>
            <p class="error" v-if="error.length > 0">{{ error }}</p>
            <p class="success" v-if="success.length > 0">{{ success }}</p>
            <div class="overlay" :class="formStatusClass()"><div class="spinner"></div></div>
            <div class="form-row">
                <div class="form-group">
                    <label for="nudgeName" class="form-label required">Campaign</label>
                    <br />
                    <input
                        v-model="fields.nudgeName"
                        type="text"
                        class="form-control required"
                        :class="formFieldClass('nudgeName')"
                        name="nudgeName"
                        id="nudgeName"
                        v-on:change="validateForm(false)"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="courseId" class="form-label">Course SIS ID</label>
                    <br />
                    <input
                        v-model="optionalFields.courseId"
                        type="text"
                        class="form-control required"
                        :class="formFieldClass('courseId')"
                        name="courseId"
                        id="courseId"
                        v-on:change="validateForm(false)"
                    />
                </div>
            </div>
            <div class="form-group upload-file">
                <label for="uploadFile" class="form-label">Add student IDs</label>
                <br />
                <input
                    type="file"
                    class="form-control-file"
                    :class="formFieldClass('file')"
                    name="file"
                    id="uploadFile"
                    aria-describedby="uploadHelp required"
                    v-on:change="fileAdded"
                    required
                />
                <div class="upload-background"></div>
                <div class="upload-icon" :class="fields.file ? 'selected' : ''"></div>
                <div class="upload-file-selected">
                    <div v-if="!fields.file">Drag and drop a CSV file to upload.</div>
                    <div v-if="fields.file">Using<br />{{ fields.file.name }}</div>
                </div>
                <div id="uploadhelp" class="form-text" v-on:click="manualFileSelect">
                    <div v-if="!fields.file">Or choose from your computer</div>
                    <div v-if="fields.file">Choose a different file</div>
                </div>
            </div>
            <div class="form-row date-row">
                <div class="form-group">
                    <label for="startDate" class="form-label required">Start Date</label>
                    <div class="date-picker-div">
                        <Datepicker
                            name="startDate"
                            id="startDate"
                            v-model="fields.startDate"
                            :class="formFieldClass('startDate')"
                            format="M/dd/yyyy"
                            placeholder="Choose a date"
                            v-on:selected="validateForm(false)"
                            required
                        ></Datepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="endDate" class="form-label required">End Date</label>
                    <div class="date-picker-div">
                        <Datepicker
                            name="endDate"
                            id="endDate"
                            v-model="fields.endDate"
                            :class="formFieldClass('endDate')"
                            format="M/dd/yyyy"
                            placeholder="Choose a date"
                            border="none"
                            v-on:selected="validateForm(false)"
                            required
                        ></Datepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="maxTries" class="form-label required">Max Tries</label>
                    <div class="date-picker-div">
                        <input
                            type="text"
                            name="maxTries"
                            id="maxTries"
                            v-model="fields.maxTries"
                            :class="formFieldClass('maxTries')"
                            placeholder="2"
                            border="none"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="form-row time-row">
                <div class="form-group">
                    <label for="startTime" class="form-label required">Start Time</label>
                    <div class="time-picker-div" :class="formFieldClass('startTime')">
                        <VueTimepicker
                            name="startTime"
                            id="startTime"
                            v-model="fields.startTime"
                            format="hh:mm A"
                            placeholder="Choose a time"
                            v-on:change="validateForm(false)"
                            required
                        ></VueTimepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="endTime" class="form-label required">End Time</label>
                    <div class="time-picker-div" :class="formFieldClass('endTime')">
                        <VueTimepicker
                            name="endTime"
                            id="endTime"
                            v-model="fields.endTime"
                            format="hh:mm A"
                            placeholder="Choose a time"
                            v-on:change="validateForm(false)"
                            required
                        ></VueTimepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="waitToRetry" class="form-label required">Wait to Retry(min)</label>
                    <div class="date-picker-div">
                        <input
                            type="text"
                            name="waitToRetry"
                            id="waitToRetry"
                            v-model="fields.waitToRetry"
                            :class="formFieldClass('waitToRetry')"
                            placeholder="360"
                            border="none"
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="nudge-form form-section">
            <h3>Nudge Builder</h3>
            <div class="form-group">
                <label for="nudgeSubject" class="form-label required">Subject</label>
                <br />
                <input
                    v-model="fields.nudgeSubject"
                    type="text"
                    class="form-control required"
                    :class="formFieldClass('nudgeSubject')"
                    name="nudgeSubject"
                    id="nudgeSubject"
                    placeholder="Type something"
                    v-on:change="validateForm(false)"
                    required
                />
            </div>
            <div class="form-group">
                <label for="message" class="form-label required">Message</label>
                <br />
                <textarea
                    v-model="fields.message"
                    class="form-control required"
                    :class="formFieldClass('message')"
                    name="message"
                    id="message"
                    v-on:change="validateForm(false)"
                    required
                ></textarea>
            </div>
            <h2>Nudge Actions</h2>
            <div class="form-row">
                <div class="form-group">
                    <label for="label1" class="form-label">Text</label>
                    <br />
                    <input
                        v-model="optionalFields.label1"
                        type="text"
                        class="form-control inline-left"
                        placeholder="Link Text"
                        name="label1"
                        id="label1"
                        v-on:change="validateForm(false)"
                    />
                </div>
                <div class="form-group">
                    <label for="href1" class="form-label">Link</label>
                    <br />
                    <input
                        v-model="optionalFields.href1"
                        type="text"
                        placeholder="URL"
                        class="form-control inline-right"
                        name="href1"
                        id="href1"
                        v-on:change="validateForm(false)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="label2" class="form-label">Text</label>
                    <br />
                    <input
                        v-model="optionalFields.label2"
                        type="text"
                        class="form-control inline-left"
                        placeholder="Link Text"
                        name="label2"
                        id="label2"
                        v-on:change="validateForm(false)"
                    />
                </div>
                <div class="form-group">
                    <label for="href2" class="form-label">Link</label>
                    <br />
                    <input
                        v-model="optionalFields.href2"
                        type="text"
                        placeholder="URL"
                        class="form-control inline-right"
                        name="href2"
                        id="href2"
                        v-on:change="validateForm(false)"
                    />
                </div>
            </div>
            <!-- <div class="survey-Type-options-forms"> -->
            <div class="form-group">
                <label for="surveyType" class="form-label">Survey Type</label>
                <select
                    name="surveyType"
                    id="surveyType"
                    v-model="fields.surveyType"
                    v-on:change="validateForm(false)"
                >
                    <option id="none" value="none" selected>No survey</option>
                    <option id="yes_no" value="yes_no">Yes/No</option>
                    <option id="multiple_choice" value="multiple_choice">Multiple Choice</option>
                </select>
            </div>
            <div class="form-group option" id="yesNoSurvey" v-if="fields.surveyType === 'yes_no'">
                <label for="question" class="form-label required">Question</label>
                <br />
                <textarea
                    v-model="fields.survey.yes_no.question"
                    type="text"
                    name="QuestionForSurveyType"
                    id="questionForSurveyType"
                    placeholder="Do you feel you are currently succeeding at Devry?"
                    required
                    v-on:change="validateForm(false)"
                ></textarea>
            </div>
            <div
                class="form-group option"
                id="multipleChoiceSurvey"
                v-if="fields.surveyType === 'multiple_choice'"
            >
                <label for="question" class="form-label required">Question</label>
                <br />
                <textarea
                    v-model="fields.survey.multiple_choice.question"
                    type="text"
                    class="form-control required"
                    name="QuestionWithOptionsForSurveyType"
                    id="questionWithOptionsForSurveyType"
                    placeholder="What resources do you currently use at Devry?"
                    required
                    v-on:change="validateForm(false)"
                ></textarea>
                <br />
                <div class="form-group inline-group">
                    <div
                        v-for="(option, index) in fields.survey.multiple_choice.options"
                        :key="option.key"
                    >
                        <label :for="'option-' + option.key" class="form-label"
                            >Option {{ option.key }}</label
                        >
                        <br />
                        <div class="form-row">
                            <input
                                v-model="fields.survey.multiple_choice.options[index].value"
                                type="text"
                                class="form-control inline-half-input"
                                placeholder="Tutor.com"
                                :name="'option-' + option.key"
                                :id="'multiple_choice-option' + option.key"
                                v-on:change="validateForm(false)"
                            />
                            <div class="form-group">
                                <br />
                                <a
                                    v-on:click="removeOption(index)"
                                    v-show="
                                        fields.survey.multiple_choice.options.length < 3
                                            ? false
                                            : true
                                    "
                                >
                                    Delete
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div
                            class="grey-button inline"
                            v-show="
                                fields.survey.multiple_choice.options.length >= 5 ? false : true
                            "
                        >
                            <label class="grey-button-label" v-on:click="addOption"
                                >+ Add option</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { uploadNudge } from '../api/nudge.js'
import moment from 'moment'
import store from '../store'

export default {
    name: 'NudgeForm',
    components: {
        Datepicker,
        VueTimepicker
    },
    props: ['editType', 'campaignId', 'nudgeGroup'],
    data() {
        return {
            parent: this.editType,
            error: '',
            success: '',
            in_progress: false,
            fields: {
                startTime: '12:00 AM',
                endTime: '11:59 PM',
                startDate: null,
                endDate: null,
                message: '',
                nudgeName: '',
                nudgeLinks: [],
                nudgeSubject: '',
                maxTries: 3,
                waitToRetry: 360,
                file: null,
                surveyType: 'none',
                survey: {
                    yes_no: {
                        type: 'yes_no',
                        question: ''
                    },
                    multiple_choice: {
                        type: 'multiple_choice',
                        question: '',
                        options: [
                            { key: 1, value: '' },
                            { key: 2, value: '' }
                        ]
                    }
                }
            },
            invalidFields: {},
            optionalFields: {
                courseId: '',
                href1: '',
                href2: '',
                label1: '',
                label2: ''
            }
        }
    },
    methods: {
        setSurveyType() {
            console.log(this.surveyType)
        },
        addOption() {
            let option = { key: this.fields.survey.multiple_choice.options.length + 1, value: '' }
            this.fields.survey.multiple_choice.options.push(option)
        },
        removeOption(selectedOption) {
            let options = []
            this.fields.survey.multiple_choice.options.forEach((option, index) => {
                if (index != selectedOption) {
                    options.push(option)
                }
            })
            options.forEach((option, index) => {
                option.key = index + 1
            })
            this.fields.survey.multiple_choice.options = options
        },
        resetForm() {
            this.error = ''
            this.success = ''
            this.in_progress = false
            let fileInput = document.getElementById('uploadFile')
            fileInput.type = 'text'
            fileInput.type = 'file'
            this.fields.startDate = null
            this.fields.endDate = null
            this.fields.startTime = '12:00 AM'
            this.fields.endTime = '11:59 PM'
            this.fields.message = ''
            this.fields.nudgeName = ''
            this.fields.nudgeLinks = []
            this.fields.nudgeSubject = ''
            this.fields.maxTries = 3
            this.fields.waitToRetry = 360
            this.fields.file = null
            this.fields.surveyType = 'none'
            this.fields.survey = {
                yes_no: {
                    type: 'yes_no',
                    question: ''
                },
                multiple_choice: {
                    type: 'multiple_choice',
                    question: '',
                    options: [
                        { key: 1, value: '' },
                        { key: 2, value: '' }
                    ]
                }
            }
            this.optionalFields.courseId = ''
            this.optionalFields.href1 = ''
            this.optionalFields.href2 = ''
            this.optionalFields.label1 = ''
            this.optionalFields.label2 = ''
            store.commit('nudgeForm/setFormValid', false)
        },
        populateForm() {
            this.error = ''
            this.success = ''

            let fileInput = document.getElementById('uploadFile')
            fileInput.type = 'text'
            fileInput.type = 'file'

            let startDate = moment.unix(this.nudgeGroup.data.startDate)
            let endDate = moment.unix(this.nudgeGroup.data.endDate)

            this.fields.startDate = startDate.format('M/DD/YYYY')
            this.fields.endDate = endDate.format('M/DD/YYYY')
            this.fields.startTime = startDate.format('hh:mm A')
            this.fields.endTime = endDate.format('hh:mm A')
            this.fields.message = this.nudgeGroup.data.nudgeMessage
            this.fields.nudgeName = this.nudgeGroup.data.nudgeName
            this.fields.nudgeSubject = this.nudgeGroup.data.nudgeTitle
            this.fields.maxTries = this.nudgeGroup.data.maxTries
            this.fields.waitToRetry = this.nudgeGroup.data.waitToRetry
            this.fields.file = new File(['default'], this.nudgeGroup.data.nudgeName + '.data.csv', {
                type: 'text/plain'
            })

            this.fields.survey = {
                yes_no: {
                    type: 'yes_no',
                    question: ''
                },
                multiple_choice: {
                    type: 'multiple_choice',
                    question: '',
                    options: [
                        { key: 1, value: '' },
                        { key: 2, value: '' }
                    ]
                }
            }
            this.fields.surveyType = 'none'
            if (typeof this.nudgeGroup.data.survey != 'undefined') {
                if (this.nudgeGroup.data.survey.type == 'yes_no') {
                    this.fields.survey.yes_no.question = this.nudgeGroup.data.survey.question
                } else if (this.nudgeGroup.data.survey.type == 'multiple_choice') {
                    this.fields.survey.multiple_choice.question = this.nudgeGroup.data.survey.question
                    this.fields.survey.multiple_choice.options = this.nudgeGroup.data.survey.options
                }

                this.fields.surveyType = Object.prototype.hasOwnProperty.call(
                    this.nudgeGroup.data.survey,
                    'type'
                )
                    ? this.nudgeGroup.data.survey.type
                    : 'none'
            }

            this.optionalFields.href1 = ''
            this.optionalFields.href2 = ''
            this.optionalFields.label1 = ''
            this.optionalFields.label2 = ''
            this.optionalFields.courseId = this.nudgeGroup.data.courseId

            if (
                typeof this.nudgeGroup.data.nudgeLinks != 'undefined' &&
                this.nudgeGroup.data.nudgeLinks.length > 0
            ) {
                this.nudgeGroup.data.nudgeLinks.forEach((link, index) => {
                    let indexVal = index + 1
                    let labelVal = 'label' + indexVal
                    let hrefVal = 'href' + indexVal
                    this.optionalFields[labelVal] = link.label
                    this.optionalFields[hrefVal] = link.href
                })
            }
            store.commit('nudgeForm/setFormValid', false)
            this.validateForm(false)
        },
        manualFileSelect() {
            document.getElementById('uploadFile').click()
        },
        fileAdded($event) {
            this.fields.file = $event.target.files[0]
            this.validateForm()
        },
        csvExport(arrData, nudgeGroup) {
            let csvContent = 'data:text/csv;charset=utf-8,'
            csvContent += [
                Object.keys(arrData[0]).join(','),
                ...arrData.map(item => Object.values(item).join(','))
            ]
                .join('\n')
                .replace(/(^\[)|(\]$)/gm, '')
            let exportName = ''
            exportName += nudgeGroup.nudgeName
            exportName += '--'
            exportName += nudgeGroup.id
            let exportTime = moment().format('MM.DD.YYYY--hh.mm')

            const data = encodeURI(csvContent)
            const link = document.createElement('a')
            link.setAttribute('href', data)
            link.setAttribute('download', exportName + '.export--' + exportTime + '.csv')
            link.click()
        },
        validateForm(submission) {
            // Highlight fields (unless this is a background validation event)
            let fieldMissing = false
            for (let key in this.fields) {
                if (!this.fields[key]) {
                    fieldMissing = true
                    if (submission) {
                        this.invalidFields[key] = true
                    }
                } else {
                    this.invalidFields[key] = false
                }
            }

            // Validate the time entries
            let validStartTime = true
            if (
                this.fields.startTime.indexOf('hh') > -1 ||
                this.fields.startTime.indexOf('mm') > -1 ||
                (this.fields.startTime.indexOf('AM') === -1 &&
                    this.fields.startTime.indexOf('PM') === -1)
            ) {
                validStartTime = false
                this.invalidFields['startTime'] = true
            }
            let validEndtTime = true
            if (
                this.fields.endTime.indexOf('hh') > -1 ||
                this.fields.endTime.indexOf('mm') > -1 ||
                (this.fields.endTime.indexOf('AM') === -1 &&
                    this.fields.endTime.indexOf('PM') === -1)
            ) {
                validEndtTime = false
                this.invalidFields['endTime'] = true
            }

            // Update the store
            if (!validStartTime || !validEndtTime || fieldMissing) {
                store.commit('nudgeForm/setFormValid', false)
                if (submission) {
                    this.error = 'Please fill out all required fields.'
                }
                return false
            } else {
                store.commit('nudgeForm/setFormValid', true)
                return true
            }
        },
        async submitNudge() {
            this.success = ''
            this.in_progress = true
            if (!this.validateForm()) {
                this.in_progress = false
                return
            }

            this.error = ''

            // Parse dates
            let startDateFormatted = moment(this.fields.startDate).format('MM/DD/YYYY')
            let endDateFormatted = moment(this.fields.endDate).format('MM/DD/YYYY')
            let finalStartDate = moment(
                startDateFormatted + ' ' + this.fields.startTime,
                'MM/DD/YYYY hh:mm A'
            ).unix()
            let finalEndDate = moment(
                endDateFormatted + ' ' + this.fields.endTime,
                'MM/DD/YYYY hh:mm A'
            ).unix()

            // Parse links

            if (this.optionalFields.label1 && this.optionalFields.href1) {
                this.fields.nudgeLinks.push({
                    linkId: 'link1',
                    label: this.optionalFields.label1,
                    href: this.optionalFields.href1
                })
            }
            if (this.optionalFields.label2 && this.optionalFields.href2) {
                this.fields.nudgeLinks.push({
                    linkId: 'link2',
                    label: this.optionalFields.label2,
                    href: this.optionalFields.href2
                })
            }

            // Parse survey
            let survey = null
            if (this.fields.surveyType !== 'none') {
                survey = JSON.stringify(this.fields.survey[this.fields.surveyType])
            }

            // Submit
            try {
                let data = {
                    campaignId: this.editType != 'create' ? this.campaignId : null,
                    editType: this.editType,
                    startDate: finalStartDate,
                    endDate: finalEndDate,
                    courseId: this.optionalFields.courseId,
                    nudgeName: this.fields.nudgeName,
                    nudgeTitle: this.fields.nudgeSubject,
                    nudgeLinks: JSON.stringify(this.fields.nudgeLinks),
                    maxTries: parseInt(this.fields.maxTries),
                    waitToRetry: parseInt(this.fields.waitToRetry),
                    message: this.fields.message,
                    file: this.fields.file,
                    survey: survey
                }

                await uploadNudge(data)
                await this.resetForm()
                this.$router.push('/')

                this.$emit('nudgeCreated', {
                    message: 'Nudge campaign created!'
                })
            } catch (err) {
                this.in_progress = false
                console.log('form error', err)
                this.error = err.data.error
                if (err.status.toString().indexOf('403') > -1) {
                    this.$router.push('/login')
                } else {
                    await this.resetForm()
                    this.$router.push('/')
                }
            }
        }
    },
    computed: {
        validForm() {
            return store.state.nudgeForm.valid
        },
        submitted() {
            return store.state.nudgeForm.submitted
        },
        formFieldClass() {
            return function(fieldName) {
                if (this.invalidFields[fieldName]) {
                    return 'invalid'
                } else {
                    return 'valid'
                }
            }
        },
        formStatusClass() {
            return function() {
                if (this.in_progress) {
                    return 'in_progress'
                } else {
                    return ''
                }
            }
        }
    },
    watch: {
        submitted() {
            if (this.submitted) {
                store.commit('nudgeForm/setSubmitted', false)
                this.submitNudge()
            }
        },
        nudgeGroup() {
            if (typeof this.nudgeGroup != 'undefined') {
                this.populateForm()
            }
        }
    },
    mounted() {}
}
</script>

<style lang="scss">
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.form {
    .overlay {
        display: none;
        &.in_progress {
            display: block;
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 2000;
            .spinner {
                border: 16px solid #f3f3f3;
                border-top: 16px solid #fec221;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                -webkit-animation: spin 1s linear infinite;
                animation: spin 1s linear infinite;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
.nudge-form.form-section {
    max-width: 1100px;
    min-height: calc(100vh - 20px);
    margin: 0 auto;
    //background-color: white;
    box-shadow: 5px 8px #eee;
    padding-left: 93px;
    padding-right: 92px;
    padding-top: 36px;
    left: 73px;
    top: 224px;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 30px;
    .success {
        border: 2px solid green;
        border-radius: 4px;
        padding: 4px 8px;
        background-color: light-green;
    }
    .error {
        border: 2px solid red;
        border-radius: 4px;
        padding: 4px 8px;
        background-color: pink;
    }
    input,
    textarea {
        height: 49px;
        width: 100%;
        left: 0px;
        top: 35px;
        padding: 3px 20px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    textarea {
        height: 151px;
        padding: 20px;
    }
    #message {
        height: 223px;
        margin-bottom: 70px;
    }
    .inline_input {
        width: 40%;
    }
    .inline-half-input {
        width: 50%;
    }
    .upload-file {
        position: relative;

        .form-control-file::-webkit-file-upload-button {
            visibility: hidden;
        }

        .upload-background {
            position: absolute;
            top: 45px;
            left: 2%;
            width: 95%;
            height: 250px;
            background-color: white;
            border-radius: 12px;
            pointer-events: none;
        }

        .upload-icon {
            width: 32px;
            height: 40px;
            position: absolute;
            left: 50%;
            top: 80px;
            margin-left: -16px;
            background-image: url(../assets/file.png);
            pointer-events: none;
        }
        .upload-icon.selected {
            background-image: url(../assets/file_selected.png);
        }

        .upload-file-selected {
            width: 160px;
            position: absolute;
            left: 50%;
            top: 135px;
            margin-left: -80px;
            text-align: center;
            font-weight: 500;
            pointer-events: none;
        }

        #uploadhelp {
            width: 150px;
            position: absolute;
            left: 50%;
            bottom: 60px;
            margin-left: -75px;
            text-align: center;
            text-decoration: underline;
            color: #0369a0;
            cursor: pointer;
        }
        #uploadhelp:hover {
            color: #07405f;
        }

        #uploadFile {
            height: 270px;
        }
    }
    #surveyType {
        height: 49px;
        width: 100%;
        left: 0px;
        top: 35px;
        padding: 0 15px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    .date-row,
    .time-row {
        .form-group {
            flex: 1;
            min-width: 120px;
        }
        label {
            width: 50% !important;
            white-space: nowrap;
        }
    }
    .date-picker-div,
    .time-picker-div {
        height: 49px;
        left: 0px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    .date-picker-div input,
    .time-picker-div input {
        height: 45px;
        padding-left: 20px;
        border: none;
        background: transparent;
    }
    h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
    }
    h3 {
        width: 100%;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 39px;
        padding-bottom: 40px;
        border-bottom: 2px solid #e5e5e5;
        margin-bottom: 41px;
    }
    #questionWithOptionsForSurveyType {
        height: 152px;
        //width: 100%;
    }
    .grey-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 25%;
        border: 2px solid #000000;
        border-radius: 8px;
        background: #e5e5e5;
    }
    .grey-button-label {
        //styleName: Button Primary;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        color: #000000;
        text-transform: uppercase;
    }
    .form-row {
        display: flex;
        flex-direction: row;
        flex-wrap: inherit;
        justify-content: flex-start;
        margin: 0px;
        gap: 20px;
    }

    .form-group {
        margin-bottom: 36px;
        flex: 1;

        .date-picker-div,
        .time-picker-div,
        input,
        textarea {
            &.invalid {
                border: 1px solid red;
                box-shadow: 1px 2px red;
            }
        }
        &.inline-group {
            input {
                display: inline;
                width: 45%;
                &.inline-right {
                    float: right;
                }
                &:invalid {
                    border: 2px dashed red;
                }
            }
        }
        label {
            &.form-label {
                width: 100%;
                //styleName: Form Label;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: left;
                &.required {
                    &:after {
                        content: '*';
                        color: black;
                        font-size: 20px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
</style>
