<template>
    <div class="page">
        <Toast ref="homeToast"></Toast>
        <Header ref="homeHeader">Overview</Header>
        <div class="mainContainer">
            <div class="tabsContainer">
                <div
                    v-for="tab in tabs"
                    v-bind:key="tab"
                    v-bind:class="['overviewTab', { selectedTab: currentTab === tab }]"
                    v-on:click="updateTabs(tab)"
                >
                    {{ tab }}
                </div>
            </div>
            <div v-if="currentTab === 'Active Campaigns'">
                <NudgeTable
                    :key="'active'"
                    :category="'active'"
                    @nudgeDisabled="nudgeDisabled"
                    @nudgeEventsExported="nudgeEventsExported"
                ></NudgeTable>
            </div>
            <div v-if="currentTab === 'Scheduled Campaigns'">
                <NudgeTable
                    :key="'scheduled'"
                    :category="'scheduled'"
                    @nudgeDisabled="nudgeDisabled"
                    @nudgeEventsExported="nudgeEventsExported"
                ></NudgeTable>
            </div>
            <div v-if="currentTab === 'Inactive Campaigns'">
                <NudgeTable
                    :key="'inactive'"
                    :category="'inactive'"
                    @nudgeDisabled="nudgeDisabled"
                    @nudgeEventsExported="nudgeEventsExported"
                ></NudgeTable>
            </div>
            <div v-if="currentTab === 'Accounts'">
                <AccountsTable
                    @accountDeleted="accountDeleted"
                    @accountCreated="accountCreated"
                ></AccountsTable>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../components/Header'
import Toast from '../../components/Toast'
import NudgeTable from '../../components/NudgeTable'
import AccountsTable from '../../components/AccountsTable'
import { checkAuth } from '../../api/session.js'
import { checkIfAdmin } from '../../api/session.js'

export default {
    name: 'HomeView',
    components: {
        Header,
        Toast,
        NudgeTable,
        AccountsTable
    },
    data() {
        return {
            currentTab: 'Active Campaigns',
            tabs: ['Active Campaigns', 'Scheduled Campaigns', 'Inactive Campaigns', 'Accounts']
        }
    },
    computed() {
        return {
            currentTabComponent: function() {
                return 'tab-' + this.currentTab.replace(' ', '').toLowerCase()
            }
        }
    },
    methods: {
        updateTabs(selected) {
            this.currentTab = selected
            this.$refs.homeHeader.tabSelected(selected)
        },
        async checkIfLoggedIn() {
            try {
                let res = await checkAuth()
                console.log('checkingauth', res)
            } catch (err) {
                console.log('checkingauth', err)
                this.$router.push('/login')
            }
        },
        async checkAuthz() {
            try {
                let res = await checkIfAdmin()
                console.log('checkingauthz', res)
                if (res.data == false) {
                    this.tabs = ['Active Campaigns', 'Scheduled Campaigns', 'Inactive Campaigns']
                }
            } catch (err) {
                console.log('checkingauthz', err)
                this.tabs = ['Active Campaigns', 'Scheduled Campaigns', 'Inactive Campaigns']
            }
        },
        createNudge() {
            this.$router.push('/create')
        },
        accountCreated(message) {
            this.$refs.homeToast.setAlertMessage(message)
            this.$refs.homeToast.toggle()
        },
        accountDeleted(message) {
            this.$refs.homeToast.setAlertMessage(message)
            this.$refs.homeToast.toggle()
        },
        nudgeCreated(message) {
            this.$refs.homeToast.setAlertMessage(message)
            this.$refs.homeToast.toggle()
        },
        nudgeDisabled(message) {
            this.$refs.homeToast.setAlertMessage(message)
            this.$refs.homeToast.toggle()
        },
        nudgeEventsExported(message) {
            this.$refs.homeToast.setAlertMessage(message)
            this.$refs.homeToast.toggle()
        }
    },
    mounted() {
        this.checkIfLoggedIn()
        // this.checkAuthz()
        this.updateTabs(this.tabs[0])
    }
}
</script>

<style lang="scss">
.mainContainer {
    background-color: white;
    padding-left: 74px;
    padding-right: 74px;
}
.tabsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 35px;
}
.overviewTab {
    padding: 16px 28px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #050505;
    cursor: pointer;
}
.selectedTab {
    background-color: #febb41;
    font-weight: bold;
}
</style>
