<template>
    <div
        id="nudgeAlert"
        class="alert alert-success"
        :class="this.show == false ? 'hidden' : ''"
        role="alert"
    >
        {{ this.message }}
    </div>
</template>
<script>
export default {
    name: 'Toast',
    data() {
        return {
            show: false,
            message: ''
        }
    },
    methods: {
        toggle() {
            if (this.show) {
                this.show = false
            } else {
                this.show = true
            }
            setTimeout(() => {
                this.show = false
            }, 3000)
        },
        setAlertMessage(msg) {
            this.message = msg.message
        }
    },
    computed: {
        showToast() {
            return this.show
        }
    }
}
</script>

<style lang="scss" scoped>
#nudgeAlert.alert.alert-success {
    background-color: #febb41;
    font-family: Poppins;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;

    &.hidden {
        display: none;
    }
}
</style>
