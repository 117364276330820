<template>
    <div class="page login-view">
        <Header :centered="true">New Campaign</Header>
        <LoginForm></LoginForm>
    </div>
</template>

<script>
import Header from '../../components/Header'
import LoginForm from '../../components/LoginForm'
import { checkAuth } from '../../api/session.js'

export default {
    name: 'HomeView',
    components: { Header, LoginForm },
    methods: {
        async checkIfLoggedIn() {
            try {
                await checkAuth()
                this.$router.push('/')
            } catch (err) {
                console.log(err)
            }
        }
    },
    mounted() {
        this.checkIfLoggedIn()
    }
}
</script>

<style lang="scss" scoped>
    .login-view {
        margin: 0 auto;
        text-align: center;

        .login-form {
            display: inline-block;
            float: none;
        }
    }
</style>
