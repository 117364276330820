<template>
    <div class="form">
        <div class="nudge-form form-section">
            <h3>Account Details</h3>
            <p class="error">{{ error }}</p>
            <p class="success">{{ success }}</p>
            <div class="form-row">
                <div class="form-group">
                    <label for="email" class="form-label required">Email</label>
                    <br />
                    <input
                        v-model="fields.email"
                        type="text"
                        class="form-control required"
                        :class="email"
                        name="email"
                        id="email"
                        v-on:change="validateForm(true)"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="role" class="form-label">role</label>
                    <br />
                    <select
                        name="role"
                        id="role"
                        v-model="fields.role"
                        v-on:change="validateForm(false)"
                    >
                        <option id="role-manager" value="manager">Manager</option>
                        <option id="role-admin" value="admin">Admin</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createAccount } from '../api/session.js'
//import moment from 'moment'
import store from '../store'

export default {
    name: 'AccountForm',
    components: {},
    props: ['editType', 'accountId', 'account'],
    data() {
        return {
            parent: this.editType,
            error: '',
            success: '',
            fields: {
                email: '',
                role: 'manager'
            }
        }
    },
    methods: {
        setSurveyType() {},
        resetForm() {
            this.error = ''
            this.success = ''

            this.fields.email = ''
            this.fields.role = 'manager'
            store.commit('accountForm/setFormValid', false)
        },
        populateForm() {
            this.error = ''
            this.success = ''

            this.fields.email = this.account.data.email
            this.fields.role = this.account.data.role

            store.commit('accountForm/setFormValid', false)
            this.validateForm(false)
        },
        validateForm(submission) {
            // Highlight fields (unless this is a background validation event)
            let fieldMissing = false

            // Update the store

            if (fieldMissing) {
                store.commit('nudgeForm/setFormValid', false)
                if (submission) {
                    this.error = 'Please fill out all required fields.'
                }
                return false
            } else {
                store.commit('nudgeForm/setFormValid', true)
                return true
            }
        },
        async submitAccount() {
            this.success = ''

            if (!this.validateForm()) {
                return
            }

            this.error = ''

            // Submit
            try {
                let data = {
                    accountId: this.editType != 'create' ? this.accountId : null,
                    editType: this.editType,
                    role: this.fields.role,
                    email: this.fields.email
                }
                await createAccount(data)
                await this.resetForm()
                this.$emit('accountCreated', {
                    message: 'Account created!'
                })
                this.$router.push('/')
            } catch (err) {
                console.log('form error', err)
                this.error = err.data.error
                if (err.status.toString().indexOf('403') > -1) {
                    this.$router.push('/login')
                }
            }
        }
    },
    computed: {
        validForm() {
            return store.state.accountForm.valid
        },
        submitted() {
            return store.state.accountForm.submitted
        }
    },
    watch: {
        submitted() {
            if (this.submitted) {
                store.commit('accountForm/setSubmitted', false)
                this.submitAccount()
            }
        },
        account() {
            if (typeof this.account != 'undefined') {
                this.populateForm()
            }
        }
    },
    mounted() {}
}
</script>

<style lang="scss">
.nudge-form.form-section {
    max-width: 1100px;
    min-height: calc(100vh - 20px);
    margin: 0 auto;
    //background-color: white;
    box-shadow: 5px 8px #eee;
    padding-left: 93px;
    padding-right: 92px;
    padding-top: 36px;
    left: 73px;
    top: 224px;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 30px;
    .success: {
        border: 2px solid green;
        border-radius: 4px;
        padding: 4px 8px;
        background-color: light-green;
    }
    .error {
        border: 2px solid red;
        border-radius: 4px;
        padding: 4px 8px;
        background-color: pink;
    }
    input,
    textarea {
        height: 49px;
        width: 100%;
        left: 0px;
        top: 35px;
        padding: 3px 20px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    textarea {
        height: 151px;
        padding: 20px;
    }
    #message {
        height: 223px;
        margin-bottom: 70px;
    }
    .inline_input {
        width: 40%;
    }
    .inline-half-input {
        width: 50%;
    }
    .upload-file {
        position: relative;

        .form-control-file::-webkit-file-upload-button {
            visibility: hidden;
        }

        .upload-background {
            position: absolute;
            top: 45px;
            left: 2%;
            width: 95%;
            height: 250px;
            background-color: white;
            border-radius: 12px;
            pointer-events: none;
        }

        .upload-icon {
            width: 32px;
            height: 40px;
            position: absolute;
            left: 50%;
            top: 80px;
            margin-left: -16px;
            background-image: url(../assets/file.png);
            pointer-events: none;
        }
        .upload-icon.selected {
            background-image: url(../assets/file_selected.png);
        }

        .upload-file-selected {
            width: 160px;
            position: absolute;
            left: 50%;
            top: 135px;
            margin-left: -80px;
            text-align: center;
            font-weight: 500;
            pointer-events: none;
        }

        #uploadhelp {
            width: 150px;
            position: absolute;
            left: 50%;
            bottom: 60px;
            margin-left: -75px;
            text-align: center;
            text-decoration: underline;
            color: #0369a0;
            cursor: pointer;
        }
        #uploadhelp:hover {
            color: #07405f;
        }

        #uploadFile {
            height: 270px;
        }
    }
    #surveyType {
        height: 49px;
        width: 100%;
        left: 0px;
        top: 35px;
        padding: 0 15px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    .date-row,
    .time-row {
        .form-group {
            flex: 1;
            min-width: 120px;
        }
        label {
            width: 50% !important;
            white-space: nowrap;
        }
    }
    .date-picker-div,
    .time-picker-div {
        height: 49px;
        left: 0px;
        border-radius: 6px;
        background: #ffffff;
        border: 2px solid #050505;
    }
    .date-picker-div input,
    .time-picker-div input {
        height: 45px;
        padding-left: 20px;
        border: none;
        background: transparent;
    }
    h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
    }
    h3 {
        width: 100%;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 39px;
        padding-bottom: 40px;
        border-bottom: 2px solid #e5e5e5;
        margin-bottom: 41px;
    }
    #questionWithOptionsForSurveyType {
        height: 152px;
        //width: 100%;
    }
    .grey-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 25%;
        border: 2px solid #000000;
        border-radius: 8px;
        background: #e5e5e5;
    }
    .grey-button-label {
        //styleName: Button Primary;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        color: #000000;
        text-transform: uppercase;
    }
    .form-row {
        display: flex;
        flex-direction: row;
        flex-wrap: inherit;
        justify-content: flex-start;
        margin: 0px;
        gap: 20px;
    }

    .form-group {
        margin-bottom: 36px;
        flex: 1;

        .date-picker-div,
        .time-picker-div,
        input,
        textarea {
            &.invalid {
                border: 1px solid red;
                box-shadow: 1px 2px red;
            }
        }
        &.inline-group {
            input {
                display: inline;
                width: 45%;
                &.inline-right {
                    float: right;
                }
                &:invalid {
                    border: 2px dashed red;
                }
            }
        }
        label {
            &.form-label {
                width: 100%;
                //styleName: Form Label;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: left;
                &.required {
                    &:after {
                        content: '*';
                        color: black;
                        font-size: 20px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
</style>
