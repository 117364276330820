<template>
    <div class="nudge-table-component" :class="componentClass" v-on:click="menuClosed()">
        <div class="table-container">
            <table class="nudges-table" v-if="accounts.length > 0 && !loading">
                <thead>
                    <tr>
                        <th class="columnEmail">Email</th>
                        <th class="columnCreated">Created</th>
                        <th class="columnRole">Role</th>
                        <th class="columnActions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="account in paginatedAccounts" :key="account.id">
                        <td class="columnEmail">{{ account.email }}</td>
                        <td class="columnCreated">
                            {{ formattedDate(account._ts) }}
                        </td>
                        <td class="columnRole">
                            {{ account.role }}
                        </td>
                        <td class="columnActions noselect" @click.stop="showMenu(account, $event)">
                            ...
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id="no_results" v-if="accounts.length === 0 && !loading">
                <div class="no_campaigns_text">No accounts yet!</div>
                <router-link to="/createAccount">
                    <a>
                        <div class="nudge_center_button">
                            <label class="nudge_center_button_label">
                                + New Account
                            </label>
                        </div>
                    </a>
                </router-link>
            </div>
            <AccountMenu
                v-if="menu.show"
                :style="subMenuStyle"
                @clicked="menuClicked"
                @closed="menuClosed"
                :tab="category"
            ></AccountMenu>
        </div>
        <div class="pagination noselect">
            <div
                class="prev"
                v-on:click="movePagePrev()"
                :class="activePage === 1 ? 'disabled' : ''"
            >
                Prev
            </div>
            <div
                v-for="page in pages"
                :key="page"
                class="page-num"
                :class="page === activePage ? 'active' : ''"
                v-on:click="setActivePage(page)"
            >
                {{ page }}
            </div>
            <div
                class="next"
                v-on:click="movePageNext()"
                :class="activePage === pages.length ? 'disabled' : ''"
            >
                Next
            </div>
        </div>
    </div>
</template>

<script>
import AccountMenu from './AccountMenu'
import { requestAccounts } from '../api/session.js'

import moment from 'moment'

export default {
    name: 'AccountsTable',
    components: { AccountMenu },
    props: ['category'],
    data() {
        return {
            loading: true,
            accounts: [],
            menu: {
                show: false,
                y: 0
            },
            pages: [1],
            activePage: 1,
            PAGE_SIZE: 9
        }
    },
    methods: {
        async getAccounts() {
            try {
                let res = await requestAccounts()
                this.accounts = res.accounts
                this.setUpPages()
                this.loading = false
            } catch (err) {
                console.log(err)
            }
        },
        setUpPages() {
            let pages = [1]
            this.activePage = 1
            let pageCount = Math.ceil(this.accounts.length / this.PAGE_SIZE)
            pages = Array.from({ length: pageCount }, (_, i) => i + 1)
            this.pages = pages
        },
        showMenu(account, $event) {
            this.menu.show = true
            this.menu.y = $event.target.offsetTop
            this.menu.account = account
        },
        menuClosed() {
            this.menu.show = false
        },
        menuClicked(clickType) {
            if (clickType === 'delete') {
                this.deleteUser()
            } else if (clickType === 'edit') {
                this.$router.push({
                    name: 'account_edit',
                    params: { accountId: this.menu.account.id }
                })
            } else if (clickType === 'reset_password') {
                this.$router.push({
                    name: 'account_password_reset',
                    params: { accountId: this.menu.account.id }
                })
            }
            this.menu.show = false
        },
        async deleteUser() {
            if (confirm('Are you sure you want to delete this user?')) {
                // await disableNudgeGroup(this.menu.nudgeGroup.id)
                this.$emit('accountDeleted', {
                    message: 'User ' + this.menu.account.email + ' deleted!'
                })
                this.getAccounts()
            }
        },
        setActivePage(page) {
            this.activePage = page
        },
        movePagePrev() {
            this.activePage--
            if (this.activePage < 1) {
                this.activePage = 1
            }
        },
        movePageNext() {
            this.activePage++
            if (this.activePage > this.pages.length) {
                this.activePage = this.pages.length
            }
        }
    },
    computed: {
        paginatedAccounts() {
            if (this.accounts.length <= this.PAGE_SIZE) {
                return this.accounts
            }
            let startPoint = this.activePage * this.PAGE_SIZE - this.PAGE_SIZE
            let endPoint = startPoint + this.PAGE_SIZE
            if (this.accounts.length < this.PAGE_SIZE) {
                return this.accounts
            }

            let subArray = this.accounts.slice(startPoint, endPoint)
            return subArray
        },
        subMenuStyle() {
            let props = {
                right: '10px',
                top: this.menu.y + 16 + 'px'
            }
            return props
        },
        componentClass() {
            return {
                loading: this.loading
            }
        },
        formattedDate() {
            return function(date) {
                return moment.unix(date).format('MM/DD/YYYY hh:mm A')
            }
        }
    },
    mounted() {
        this.getAccounts()
    }
}
</script>

<style lang="scss" scoped>
.nudge-table-component {
    position: relative;
    transition: opacity 500ms;
    opacity: 1;
}
.nudge-table-component.loading {
    opacity: 0;
}
.table-container {
}
table {
    width: 100%;
}

th {
    font-family: SourceSansProBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: black;
}
td {
    width: 30%;
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: black;
}
td.columnTitle {
    font-family: SourceSansProBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: black;
}
tr {
    width: 100%;
    height: 60px;
}
tbody {
    tr:nth-child(2n + 1) {
        background-color: #f6f6f6;
    }
    tr:nth-child(2n) {
        background-color: #ffffff;
    }
}
th,
td {
    padding: 12px 31px;
}
th.columnTitle,
td.columnTitle {
    width: 50%;
}
th.columnActions,
td.columnActions {
    width: 5%;
    padding: 0 20px 20px;
    font-size: 36px;
    font-weight: bold;
}
td.columnActions {
    cursor: pointer;
}
th.columnTitle,
td.columnTitle {
}
.nudge_center_button {
    height: 51px;
    width: 258px;
    border-radius: 8px;
    padding: 10px, 20px, 10px, 20px;
    background: #fec221;
    border: 2px solid #000000;
    cursor: pointer;
}
.nudge_center_button_label {
    //styleName: Button Primary;
    font-family: Poppins;
    color: #000000;
    text-transform: uppercase;
    min-width: 250px;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 16px 45px;
    cursor: pointer;
}
#no_results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 12px;
    height: 549px;
}
.no_campaigns_text {
    margin-bottom: 15px;
}
.pagination {
    margin-top: 50px;
    padding-bottom: 50px;
    .prev {
        margin-right: 5px;
        padding: 5px 0;
        font-weight: bold;
        cursor: pointer;
    }

    .prev.disabled {
        cursor: default;
        color: lightgray;
    }

    .page-num {
        margin: 0 4px;
        padding: 5px 7px;
        cursor: pointer;
    }

    .page-num.active {
        background: #fec221;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: bold;
    }

    .next {
        margin-left: 5px;
        padding: 5px 0;
        font-weight: bold;
        cursor: pointer;
    }

    .next.disabled {
        cursor: default;
        color: lightgray;
    }
}
</style>
