import axios from 'axios'
import config from '../config/config.js'
const timeout = 30000

axios.defaults.withCredentials = true

const requestAccounts = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/get_accounts`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const loadAccount = accountId => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/load_account?accountId=${accountId}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}


const checkAuth = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/check_auth`
        })
        .then(() => {
            resolve()
        })
        .catch(err => reject(err))
    })
}

const checkIfAdmin = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/check_admin`
        })
        .then(() => {
            resolve()
        })
        .catch(err => reject(err))
    })
}

const createAccount = (email, password) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'POST',
            url: `${config.host}/login`,
            data: {
                email,
                password
            }
        })
        .then(() => {
            resolve()
        })
        .catch(err => { 
            reject(err.response)
        })
    })
}

const login = (email, password) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'POST',
            url: `${config.host}/login`,
            data: {
                email,
                password
            }
        })
        .then(() => {
            resolve()
        })
        .catch(err => { 
            reject(err.response)
        })
    })
}

export { requestAccounts, checkAuth, checkIfAdmin, login, loadAccount, createAccount }