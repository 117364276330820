import Vue from 'vue'
import Vuex from 'vuex'
import routes from './state/routes.js'
import nudgeForm from './state/nudgeForm.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    nudgeForm,
    routes
  },
  strict: false
})

export default store
