<template>
    <div class="page">
        <Toast ref="accountToast"></Toast>
        <Header>Edit Account</Header>
        <AccountForm
            @accountSaved="accountSaved"
            :editType="parent"
            :accountId="accountId"
            :account="this.account"
        ></AccountForm>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../../components/Header'
import Toast from '../../components/Toast'
//import NudgeForm from '../../components/NudgeForm'
import AccountForm from '../../components/AccountForm'
import Footer from '../../components/Footer'
import { checkAuth } from '../../api/session.js'
import { loadAccount } from '../../api/session.js'
//import { loadCampaign } from '../../api/nudge.js'
//import moment from 'moment'

export default {
    name: 'AccountEditView',
    props: ['accountId'],
    data() {
        return {
            parent: 'edit',
            account: {}
        }
    },
    components: {
        Header,
        Toast,
        AccountForm,
        Footer
    },
    methods: {
        async checkIfLoggedIn() {
            try {
                await checkAuth()
            } catch (err) {
                this.$router.push('/login')
            }
        },
        accountSaved(message) {
            this.$refs.accountToast.setAlertMessage(message)
            this.$refs.accountToast.toggle()
        },
        async preLoadAccount() {
            let res = await loadAccount(this.accountId)
            this.account = res.data
        },
        deactivateNudge() {},
        duplicateNudge() {
            this.$router.push({
                name: 'duplicate',
                params: { nudgeGroupId: this.nudgeGroupId }
            })
        }
    },
    mounted() {
        this.checkIfLoggedIn()
        this.preLoadAccount()
    }
}
</script>

<style lang="scss"></style>
