<template>
    <div class="page">
        <Toast ref="dupeToast"></Toast>
        <Header>Duplicate Campaign</Header>
        <NudgeForm
            @nudgeSaved="nudgeSaved"
            :editType="parent"
            :campaignId="nudgeGroupId"
            :nudgeGroup="this.originalNudgeGroup"
        ></NudgeForm>
        <Footer
            @exportToCSVFile="csvExport"
            @duplicateNudge="duplicateNudge"
            @deactivateNudge="deactivateNudge"
        ></Footer>
    </div>
</template>

<script>
import Header from '../../components/Header'
import Toast from '../../components/Toast'
import NudgeForm from '../../components/NudgeForm'
import Footer from '../../components/Footer'
import { checkAuth } from '../../api/session.js'
import { loadCampaign } from '../../api/nudge.js'
//import { loadCampaignExport } from '../../api/nudge.js'
//import moment from 'moment'

export default {
    name: 'DuplicateView',
    props: ['nudgeGroupId'],
    data() {
        return {
            parent: 'copy',
            originalNudgeGroup: {}
        }
    },
    components: {
        Header,
        Toast,
        NudgeForm,
        Footer
    },
    methods: {
        async checkIfLoggedIn() {
            try {
                await checkAuth()
            } catch (err) {
                this.$router.push('/login')
            }
        },
        nudgeSaved(message) {
            this.refs.dupeToast.setAlertMessage(message)
            this.refs.dupeToast.toggle()
        },
        async loadNudgeCampaign() {
            let res = await loadCampaign(this.nudgeGroupId, false)

            this.originalNudgeGroup = res.data
        },
        async csvExport() {
            /*
            //let csvContent = 'data:text/csv;charset=utf-8,'
            let importHeader = this.originalNudgeGroup.data.importHeader
            //console.log('originalNudgeGroup', importHeader)
            let arrData = await loadCampaignExport(this.nudgeGroupId)

            //console.log('header', importHeader)
            //            console.log('DATA!!!!!', arrData.data)

            return false
            
            csvContent += [
                Object.keys(arrData[0]).join(','),
                ...arrData.map(item => Object.values(item).join(','))
            ]
                .join('\n')
                .replace(/(^\[)|(\]$)/gm, '')
            let exportName = ''
            exportName += this.originalNudgeGroup.data.nudgeName
            exportName += '--'
            exportName += this.originalNudgeGroup.data.id
            let exportTime = moment().format('MM.DD.YYYY--hh.mm')

            const data = encodeURI(csvContent)
            const link = document.createElement('a')
            link.setAttribute('href', data)
            link.setAttribute('download', exportName + '.export--' + exportTime + '.csv')
            link.click()
            */
        },
        deactivateNudge() {},
        duplicateNudge() {}
    },
    mounted() {
        this.checkIfLoggedIn()
        this.loadNudgeCampaign()
    }
}
</script>

<style lang="scss"></style>
