<template>
    <div>
        <div id="footer_white">
            <div class="footerButtonsContainer">
                <div
                    v-if="currentPage === 'edit' || currentPage === 'duplicate'"
                    class="createLinksContainer"
                >
                    <div class="deactivateButtonContainer" v-if="currentPage === 'edit'">
                        <a v-on:click="deactivateNudge">
                            <div id="footer_button" :class="formValid ? '' : 'disabled'">
                                <label id="footer_button_label">
                                    Deactivate Nudge Campaign
                                </label>
                            </div>
                        </a>
                    </div>
                    <div class="duplicateButtonContainer" v-if="currentPage === 'edit'">
                        <a v-on:click="duplicateNudge">
                            <div id="footer_button" :class="formValid ? '' : 'disabled'">
                                <label id="footer_button_label">
                                    Duplicate Nudge Campaign
                                </label>
                            </div>
                        </a>
                    </div>
                    <div class="exportButtonContainer">
                        <a v-on:click="exportFile">
                            <div id="footer_button" :class="formValid ? '' : 'disabled'">
                                <label id="footer_button_label">
                                    EXPORT CSV
                                </label>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    methods: {
        deactivateNudge() {
            //store.commit('nudgeForm/setSubmitted', true)
        },
        duplicateNudge() {
            this.$emit('duplicateNudge')
        },
        exportFile() {
            this.$emit('exportToCSVFile')
        }
    },
    computed: {
        currentPage() {
            return this.$route.name
        },
        formValid() {
            return true
        }
    }
}
</script>
>
<style lang="scss" scoped>
.footerButtonsContainer {
}
#footer_black {
    background: #050505;
    height: 84px;
    width: 100%;
    padding-left: 76px;
    display: flex;
    flex-direction: row;
}
#footer_white {
    background: #ffffff;
    height: 84px;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 0px 40px 0px;
}
#setting_button {
    background: #fec221;
    width: 74px;
    height: 84px;
}
#settings_button_label {
    font-family: SourceSansProBold;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    color: #050505;
    text-align: left;
    border-top: 57px;
    border-left: 13px;
    text-transform: uppercase;
    margin-top: 6px;
    margin-left: 13px;
}
#settings_logo {
    margin-top: 21px;
    margin-left: 23px;
}
#devry_logo {
    margin-top: 42px;
}
#devry_logo_text {
    margin-left: 6.11px;
    margin-top: 43.12px;
}
#white_labels {
}
#portal_label {
    font-family: Poppins;
    font-size: 34px;
    min-width: 225px;
    font-style: normal;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
    margin-left: 76px;
}
#page_label {
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
    margin-left: 28px;
    color: #050505;
}
.cancel {
    color: #0668a0;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
}
.cancel:hover {
    text-decoration: underline;
}
.deactivateButtonContainer,
.duplicateButtonContainer,
.exportButtonContainer {
    display: inline-block;
    #footer_button {
        margin-top: 0px;
    }
}
.createLinksContainer {
}
#footer_button {
    height: 51px;
    border-radius: 8px;
    padding: 10px, 20px, 10px, 20px;
    background-color: #e5e5e5;
    border: 2px solid #000000;
    cursor: pointer;
    margin-right: 40px;
    cursor: pointer;
}
#footer_button.disabled {
    background-color: #e5e5e5;
    border: 2px solid #707070;
    pointer-events: none;

    label {
        color: #949494;
    }
}
#footer_button_label {
    //styleName: Button Primary;
    font-family: Poppins;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 15px 20px;
    cursor: pointer;
}
</style>
