const state = () => ({
    valid: false,
    submitted: false
})

// Getters
const getters = {}

// Actions
const actions = {}

// Mutations
const mutations = {
    setFormValid: (state, valid) => {
        state.valid = valid
    },
    setSubmitted: (state, submitted) => {
        state.submitted = submitted
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
