import axios from 'axios'
import config from '../config/config.js'
const timeout = 300000

axios.defaults.withCredentials = true

const uploadNudge = form => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        let data = new FormData()
        for (let key in form) {
            data.append(key, form[key])
        }

        axios({
            method: 'POST',
            url: `${config.host}/preview_nudges`,
            data: data
        })
        .then((res) => {
            console.log('Preview: ' + res.data.preview)
            return axios({
                method: 'POST',
                url: `${config.host}/nudges`,
                data: data
            })
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => { 
             reject(err.response)
            } )
    })
}

const requestNudgeGroups = category => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/get_nudge_groups?category=${category}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const disableNudgeGroup = nudgeGroupId => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/disable_nudge_group?nudgeGroupId=${nudgeGroupId}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const exportNudgeEvents = nudgeGroupId => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/export_nudge_events?nudgeGroupId=${nudgeGroupId}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const loadCampaign = ( nudgeGroupId, includeStats ) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/load_nudge_group?nudgeGroupId=${nudgeGroupId}&includeStats=${includeStats}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const loadCampaignExport = nudgeGroupId => {
    return new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request took too long to complete')), timeout)

        axios({
            method: 'GET',
            url: `${config.host}/load_nudge_group_export?nudgeGroupId=${nudgeGroupId}`
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}


export { uploadNudge, requestNudgeGroups, loadCampaign, disableNudgeGroup, exportNudgeEvents,loadCampaignExport }