<template>
    <div class="login-form form-section">
        <div class="form-header">
            <h3>Nudge Portal</h3>
        </div>
        <div class="form">
            <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input v-model="email" type="text" name="email" id="email" />
            </div>
            <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input v-model="password" type="password" name="password" id="password" />
            </div>
            <button v-on:click="submit()">Submit</button>
            <p class="error">{{error}}</p>
        </div>
    </div>
</template>

<script>
import { login } from '../api/session.js'

export default {
    name: "LoginForm",
    components: {},
    props: {},
    data() {
        return {
            email: '',
            password: '',
            error: ''
        }
    },
    methods: {
        async submit() {
            this.error = ''
            try {
                await login(this.email, this.password)
                this.$router.push('/')
            } catch (res) {
                this.error = res.data.message
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .login-form {
        margin-top: 132px;
        border: 2px solid #000000;
        width: 542px;
        height: 471px;

        .form-header {
            height: 98px;
            border-bottom: 2px solid #000000;

            h3 {
                height: 98px;
                line-height: 98px;
                font-weight: bold;
            }
        }

        .form {
            padding: 42px;

            .form-group {
                text-align: left;

                label {
                    font-weight: bold;
                }
                input {
                    height: 49px;
                    width: 100%;
                    left: 0px;
                    top: 35px;
                    padding: 3px 20px;
                    border-radius: 6px;
                    background: #ffffff;
                    border: 2px solid #050505;
                }
            }

            button {
                height: 51px;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 15px;
                border-radius: 8px;
                padding: 10px, 20px, 10px, 20px;
                background: #fec221;
                border: 2px solid #000000;
                cursor: pointer;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .error {
            color: red;
        }
    }
</style>
