<template>
    <div class="nudge-menu noselect">
        <div class="inner">
            <div class="option" v-on:click="clicked('delete')">Delete</div>
            <div class="option editOption" v-on:click="clicked('edit')">
                Edit
            </div>
            <div class="option" v-on:click="clicked('export')">Reset Password</div>
            <div class="close-menu" v-on:click="close">...</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountMenu',
    components: {},
    props: ['tab'],
    data() {
        return {}
    },
    methods: {
        close() {
            this.$emit('closed')
        },
        clicked(option) {
            this.$emit('clicked', option)
        }
    }
}
</script>

<style lang="scss" scoped>
.nudge-menu {
    position: absolute;
    height: 230px;
    width: 166px;
    background-color: white;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 58px 26px 0;

        .option {
            width: 120px;
            height: 40px;
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                opacity: 0.3;
            }
        }
        .option:hover {
            text-decoration: underline;
        }

        .close-menu {
            position: absolute;
            top: 0;
            right: 9px;
            line-height: 5px;
            font-family: SourceSansPro;
            font-size: 36px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
</style>
